var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("setup-table", {
        attrs: {
          title: "User",
          items: _vm.items,
          headers: _vm.headers,
          loading: _vm.loading,
          clearItem: !_vm.showForm || !_vm.editedItem.id,
          showSearch: "",
          hideRowSelect: "",
          "hide-menu": "",
          pagination: "",
        },
        on: {
          createItem: function ($event) {
            return _vm.createItem()
          },
          editItem: _vm.editItem,
        },
        scopedSlots: _vm._u(
          [
            _vm.showForm
              ? {
                  key: "itemForm",
                  fn: function () {
                    return [
                      _c("user-form", {
                        ref: "userForm",
                        attrs: {
                          value: _vm.editedItem,
                          roles: _vm.roles,
                          approvalLevels: _vm.approvalLevels,
                          tripTypes: _vm.tripTypes,
                          approvalLevelsById: _vm.approvalLevelsById,
                          refreshUser: _vm.refresh,
                        },
                        on: {
                          showAssignRole: _vm.showAssignRole,
                          submit: _vm.saveItem,
                          remove: _vm.removeItem,
                          closeForm: _vm.closeForm,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("assign-role", {
        ref: "assignRoleModal",
        attrs: { value: _vm.activeRole },
        on: { refresh: _vm.refresh },
      }),
      _c("location-manager", {
        ref: "slaManager",
        on: { refresh: _vm.refresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }